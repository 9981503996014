@use "sass:math";
@import "./variables_mixins/mixins";

nav .navbar-nav,
.preview-navbar-inner {
  .nav-link{
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding-right: 0.7rem !important;
    padding-left: 0.7rem !important;
    height: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .icon-bars {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    padding-bottom: 2px;
  }

  .icon-bar {
    display: block;
    width: 25px;
    height: 1px;
    background-color: #fff;
    margin-bottom: 7px;
    border-radius: 1px;
    &:last-child{
      margin-bottom: 0;
    }
  }

  .icon-farm-bh {
    height: 24px !important;
    margin-top: 0px !important;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
  }
  .icon-farm-cog.large{
    vertical-align: middle;
    display: inline-block;
  }
}

.navbar {
  background-color: $nav-bar-color;
  color: $white-color;
  margin-bottom: 0px;
  height: $nav-bar-height;

  @media (max-width: 576px) { // mobile view
    flex-wrap: nowrap;
    padding: 0 !important;

    button.navbar-toggler {
      display: none;
    }

    #navbarNavDropdown {
      min-height: 48px;
      width: 100%;
      display: block;

      .navbar-nav {
        flex-direction: row;
        
        .nav-item {
          .nav-link {
            width:fit-content;
            white-space: nowrap;
          }

          .nav-dropdown {
            position: absolute;
            left: 0;
            padding: 1em 2em;
      
            .nav-menu:first-child {
              padding-top: 0;
            }

            .nav-menu:last-child {
              padding-bottom: 0;
            }

            h4 {
              font-size: 1.2em;
              display: block;
            }

            ul li a {
              font-size: 1.1em;
              margin: 4px;
              display: block;
            }
          }

          // Hide Appriss Admin menu on ultra small viewports
          @media (max-width: 360px) { 
            & [data-target="#system-admin-dropdown"], #system-admin-dropdown {        
             display: none !important;
            }
          }
        }

        .user-profile-dropdown {
          position: absolute;
          right: 0;
          max-height: 48px;
          max-width: 178px;
          z-index: 1;
          display: flex;
          flex-direction: column;

          .dropdown-toggle:after {
            display: none;
          }

          #user-dropdown-link {
            max-width: 45px;
            align-self: end;

            .mobile-user-profile {
              background-image: url("~images/profile.svg");
              height: 24px;
              width: 24px;
            }
          }
        }
      }
    }
  } // mobile view end

  .navbar-nav {
    float: none;
  }
}

.navbar .navbar-nav > li > a {
  color: $white-color;
  &:hover, &:focus {
    color: $white-color;
  }
  &:active, &:visited {
    @include nav-background-and-text-color;
  }
  > i {
    margin-right: 3px;
  }
}

.navbar .navbar-nav >li > a {
  transition: background-color .3s ease;
  &:hover, &[aria-expanded="true"]{
    @include nav-background-and-text-color;
  }
}

.navbar .navbar-nav > li > .dropdown-menu {
  background-color: $nav-dropdown-color;
  margin: 0px 0px;
  right: 0;
  left: inherit;
  padding: 10px;
  &:after {
    left: 88%;
  }
}

.navbar .navbar-nav > li > .dropdown-menu .dropdown-item {
  text-align: left;
  color: #fff;
  transition: background .3s ease;
  @include body2;
  &:hover {
    text-decoration: underline;
    background: $nav-dropdown-color;
  }

  &.logout-link{
    color: #fff;
    text-align: center;
    text-decoration: none;
    margin-top: 5px;
    &:hover {
      color: #fff;
      text-align: center;
      text-decoration: none;
    }
  }
}

.nav-dropdown {
  @include nav-background-and-text-color;
  position: absolute;
  top: $nav-bar-height;
  width: 100vw;
  left: calc(-50vw + 50%);
  z-index: 1000;
  .nav-menu {
    .col{
      //@include make-col(3, $columns: 15)
      flex: 0 0 ((15/3) + '%');

    }
    padding-bottom: 10px;
    a {
      color: $white-color;
    }
    &:first-child {
      padding-top: 20px;
    }
    &:last-child {
      padding-bottom: 50px;
    }
  }
  ul {
    list-style: none;
    margin: 0px 0px;
    padding-left: 0;
    li a{
      @include body2;
    }
  }
}

.narxcare-breadcrumb {
  margin-top: 10px;
  @include body3;
  a {
    color: #1E1432;
  }
}

#brand {
  width: 100%;
 
  .logo {
    margin-top: 3px;
  }
  a {
    color: black;
    &:hover, &:active, &:visited {
      color: black;
      text-decoration: none;
    }
  }
}

.brand-default-height {
  height: 6rem;
}

.login-branding {
  margin-top: 10px;
  margin-bottom: 15px;
}

.aware-logo {
  height: 22px;
  width: 81px;
}

.collapse {
  transition: height 0.5s ease;
}

.tabbed-nav {
  ul {
    border-bottom: 3px solid $button-color;
    .nav-item {
      margin-right: 4px;
      margin-bottom: 0px;
      .nav-link {
        background-color: #E0DFE0;
        color: black;
        &:hover{
          border-color: darken(#E0DFE0, 10%);
          background-color: darken(#E0DFE0, 10%);
        }

        &.active{
          border-color: transparent;
          background-color: $button-color;
          color: $white-color;
          &:hover{
            border-color: darken($button-color, 10%);
            background-color: darken($button-color, 10%);
          }
        }
      }
    }
  }
}

.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  background-color: $button-color;
  background-image: none;
  color: #fff;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: $button-color;
  color:$white-color;

  a {
    color:$white-color;
    text-decoration: none;
  }
}

.dropdown-menu {
  .divider{
    height: 2px;
    background-color: #e5e5e5;
    border-bottom: 1px solid white;
  }
}

.narxcare-breadcrumb { // ul
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;
  margin-top: 10px;
  a {
    color: #1E1432;
  }
}
